export default{
    innerMove: (!document.referrer || document.referrer.match(/newsoline|localhost/i)) ? true : false,
    parentRetern: function(el){
        return el.parentNode
    },
    pinnedCheck: function(el){
        const isLink = (el && el.nodeName && (el.nodeName === 'A'))
        const isButton = (el && el.nodeName && (el.nodeName === 'BUTTON'))
        const isScreen = (el && el.id && (el.id.match(/app-/i)))
        return (isLink || isButton || isScreen)
    },   
    pinnedCheckA: function(el){
        const isLink = (el && el.nodeName && (el.nodeName === 'A'))
        const isButton = (el && el.nodeName && (el.nodeName === 'BUTTON' && !(el.id && (el.id.match(/app-/i)))))
        // const isScreen = (el && el.id && (el.id.match(/app-/i)))
        return (isLink || isButton)
    },
    getLink: function(link, vueContext){
        const urlBase = (this.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
        const transferLink = `${urlBase}/to?link=${encodeURI(link)}`
        // return (this.isPost(vueContext)) ? transferLink : link
        return link
    },
    isTopen: (document.location.pathname.match(/\/newzFeed/i)) ? true : false,
    isWopen: (document.location.pathname.match(/\/allNews/i)) ? true : false,
    isBack: (document.location.pathname.match(/\/pwa2|\/pwa1|\/today|\/digest/i)) ? true : false,
    hasReferrerHard: function(){
        const condition1 = (document.location.href.match(/infox/i) && document.referrer.match(/infox/i))
        const condition2 = (document.location.href.match(/24smi|adwile/i) && document.referrer.match(/24smi|adwile/i))
        const condition3 =(document.location.href.match(/smi2/i) && document.referrer.match(/smi2/i))
        return (condition1 || condition2 || condition3)
    },
    fromPush: (document.location.search.match(/fromPush/i)) ? true : false,
    fromPush1: (document.location.search.match(/fromPush=1/i)) ? true : false,
    fromPush2: (document.location.search.match(/fromPush=2/i)) ? true : false,
    fromPush3: (document.location.search.match(/fromPush=3/i)) ? true : false,
    fromPush4: (document.location.search.match(/fromPush=4/i)) ? true : false,
    fromPush5: (document.location.search.match(/fromPush=5/i)) ? true : false,
    fromPush6: (document.location.search.match(/fromPush=6/i)) ? true : false,
    backendHost: (process.env.NODE_ENV === 'development') ? 'https://localhost:3000' : process.env.VUE_APP_FIREBASE_BACKEND,
    isDevelopment: (process.env.NODE_ENV === 'development'),
    consoleLog: function(a='', b='', c='', d=''){
        if (this.isDevelopment) console.log(a, b, c, d)
    },
    randomInt: function(min, max){
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    shuffleArray: function(arr) {
        const newArr = arr.slice()
        for (let i = newArr.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        }
        return newArr        
    },
    counterID: function(){
        let counterID = process.env.VUE_APP_METRIKA_STOPNEWS
        if (document.location.origin.match(/newzfeed/i)) counterID = process.env.VUE_APP_METRIKA_NEWZFEED
        if (document.location.origin.match(/rmnews/i)) counterID = process.env.VUE_APP_METRIKA_RMNEWS
        if (document.location.origin.match(/mirnovostey/i)) counterID = process.env.VUE_APP_METRIKA_MIRNOVOSTEY
        return counterID
    },
    safeGoal: function(goalName, vueContext=null, observe=true){
        if (observe) this.consoleLog('сабмит', goalName)
        const counterID = this.counterID()
        const counterName = `yaCounter${counterID}`
        try {
            if (window[counterName]){
                window[counterName].reachGoal(goalName)
            }else{
                let attemptCount = 0
                const trySend = window.setInterval(function(){
                    attemptCount = attemptCount + 1
                    if (window[counterName]){
                        window[counterName].reachGoal(goalName)
                        clearInterval(trySend)
                    }else if (attemptCount === 60){
                        clearInterval(trySend)
                    }                    
                }, 500)
            }
        } catch(e) {
            console.log(e)
        }
    },
    hit: function(){
        //https://yandex.ru/support/metrica/code/counter-spa-setup.html
        console.log('Hit to Metrika') /////////////////////////////////////////////////////////////////////////////////////////////////////////
    },
    async timeSend(timeStart){
        if (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK){
            const timeNow = parseInt(new Date().getTime())
            const timeDef = timeNow - parseInt(timeStart)
            const url = `${process.env.VUE_APP_HOST_NAME_LOCK}/monitoring/flowready.php?t=${timeDef}`
            await fetch(url)
        }
    },
    itemsCount: function(blockHeight){
        const maxNumOfBlocks = Math.floor(screen.height / blockHeight)
        // const k = 3/5
        const k = 1/3
        const num = Math.floor(maxNumOfBlocks * k) 
        return num
    },
    setFullscreen: function(){
        const permitted = (this.isDevelopment) ? true : (this.hasReferrer() && !this.fromPush)
        if(permitted){        
            try{
                if (document.documentElement.mozRequestFullScreen)  document.documentElement.mozRequestFullScreen()
                if (document.documentElement.webkitRequestFullscreen) document.documentElement.webkitRequestFullscreen()
                if (document.documentElement.msRequestFullscreen) document.documentElement.msRequestFullscreen()
                document.documentElement.requestFullscreen()
            }catch(e){
                this.consoleLog('фуллскрин ошибка', e)
            }
        }
        return true
    },
    fullScreenAbility: function(){
        return (!!document.documentElement.mozRequestFullScreen || !!document.documentElement.webkitRequestFullscreen || 
            !!document.documentElement.msRequestFullscreen || !!document.documentElement.requestFullscreen) ? true : false
    },
    restAPIpost: function(id){
            const sourceMatch = document.location.href.match(/source=([^&]+)/i)
            const source = (sourceMatch && sourceMatch[1]) ? sourceMatch[1] : 'stopnews'
            // const urlBase = (process.env.VUE_APP_LOCAL_PHP === '1') ? 'http://localhost/' : process.env.VUE_APP_HOST_NAME
            const urlBase = (this.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            // const urlEnd = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? `content/mock/post/?p=${id}` : `content/post/?p=${id}&source=${source}`
            // const url = urlBase+urlEnd
            const url = `${urlBase}/content/post/?p=${id}&source=${source}`
            return url        
    },
    apiIDList: function(){
            const urlBase = (process.env.VUE_APP_LOCAL_PHP === '1') ? 'http://localhost/' : process.env.VUE_APP_HOST_NAME
            const urlEnd = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? `api/mock/id/?t=${Date.now()}` : `api/id/?t=${Date.now()}`
            const url = urlBase+urlEnd
            return url                
    },
    restAPIlist: function(feedName='main'){
        const urlMatches = document.location.href.match(/http.+?\/\/.+?\//i)
        const testHost = urlMatches[0] 
        const remoteHost = (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME) ? process.env.VUE_APP_HOST_NAME : testHost
        const urlBase = (process.env.VUE_APP_LOCAL_PHP === '0') ? remoteHost : 'http://localhost/'
        return `${urlBase}content/cached/stuff/${feedName}.json` //?t=${Date.now()}
    },
    hasReferrer: function(){
        let result = false
        const condition1 = (document.referrer === process.env.VUE_APP_CONTENT_SOURCE_1)
        const condition2 = (document.referrer === process.env.VUE_APP_CONTENT_SOURCE_2)
        const condition3 = (document.location.href.match(/source=stopnews/i))
        const condition4 = (document.location.href.match(/source=newzfeed/i))
        const condition5 = (document.referrer.match(/infox|24smi|adwile/i))
        const condition6 = (document.location.href.match(/infox|infox/i))
        const condition7 = (document.location.href.match(/24smi|adwile/i))
        const condition8 =(document.location.href.match(/smi2/i) || document.referrer.match(/smi2/i))
        const condition9 = (document.cookie.match(/from_external=1/i))
        const condition10 = (document.location.href.match(/fromPush/i))
        const condition11 = (document.location.href.match(/sparrow/i) || document.referrer.match(/sparrow/i) )
        const condition12 = (document.location.href.match(/t=teasers/i))
        const exclude = (document.referrer.match(/metrika\.yandex\.ru/i))
        if ((condition1 || condition2 || condition3 || condition4 || condition5 
            || condition6 || condition7 || condition8 
            || condition9 || condition10 || condition11 || condition12) && !exclude){
            result = true
            document.cookie = 'from_external=1; path=/'
        }
        return result
    },
    refSpr: function () { return (document.location.href.match(/sparrow/i) || document.referrer.match(/sparrow/i)) }, 
    ref24: function(){   return (document.location.href.match(/24smi|adwile/i) || document.referrer.match(/24sm|adwilei/i))    },
    refSmi2: function(){    return (document.location.href.match(/smi2/i) || document.referrer.match(/smi2/i))    },  
    refInfox: function(){   return (document.location.href.match(/infox/i) || document.referrer.match(/infox/i))    },
    refStopHard: function(){ return ( document.referrer.match(/stopnews/i) ? true : false ) },
    ref24Hard: function(){ return ( document.referrer.match(/24smi/i) ? true : false ) },
    refInfoxHard: function(){ return ( document.referrer.match(/infox/i) ? true : false ) },
    refSmi2Hard: function(){ return ( document.referrer.match(/smi2/i) ? true : false )    },  

    smi24Ref: function(){   return (document.referrer.match(/24smi/i)) ? true : false    },
    smi24UTM: function(){   return document.location.href.match(/24smi/i) ? true : false    },
    getIdFromAddressBar: function(){
        const matchP = document.location.href.match(/\?p=([\d]+)/i)
        return (matchP && matchP[1]) ? matchP[1] : false
    },
    smi24Press: function(){
        return (document.referrer.match(/24smi\.press/i)) ? true : false
    },
    smi24Info: function(){
        return (document.referrer.match(/24smi\.info/i)) ? true : false
    },
    /*
    hasOpener: function(){
        // return (!window.opener) ? false : true
        return false
    },
    */
    isProd: function(){
        return (process.env.VUE_APP_HOST_NAME === document.location.origin+'/')
    },
    isRotator: function(vueContext){
        const routeName = vueContext.$route.name
        return ( routeName === 'app' || routeName === 'pwa' 
            || routeName === 'pwa3' || routeName === 'pwa5' 
            || routeName === 'pwa6' || routeName === 'pwa7')
    },
    isPost: function(vueContext){
        return (vueContext.$route.name === 'post')
    },
    isHome: function(vueContext){
        return (vueContext.$route.name === 'home')
    },        
    localResources: function(){
        return (process.env.VUE_APP_LOCAL_RESOURCES === '1')
    },
    async getPixel(zeroPixel){
        await fetch(zeroPixel)
    },
    closureIncrement: function () {
        let count = 0
        return function () {
            return count++
        }
    }, 
    uuidv4: function () {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    }, 
}