import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultView from '@/views/DefaultView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultView
  },
  {
    path: '/index.html',
    name: 'index',
    component: DefaultView
  },
  {
    path: '/agregator.html',
    name: 'agregator',
    component: DefaultView
  },
  {
    path: '/rotator.html',
    name: 'rotator',
    component: DefaultView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 }
  }
})

export default router
