<template>
  <div id="app" class="pt-5">
    <NavPanel></NavPanel>
    <!-- с анимацией -->
    <transition name="fade-delayed">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import utils from '@/functions/functions'
import NavPanel from './components/NavPanel.vue';
export default {
  components: {
    NavPanel
  },
  computed: {

  },
  created: function(){
    // console.log('this.$router', this.$router)
  }
}
</script>

<style lang="sass">
html
  font-size: 125%
body
  // background-color: #eaeaea !important

.fade-delayed-leave-active
  transition: opacity .2s
.fade-delayed-enter-active 
  transition: opacity 0.15s ease-in 0.2s
.fade-delayed-enter, .fade-delayed-leave-to
  opacity: 0

.fade-leave-active
  transition: opacity .2s
.fade-enter-active 
  transition: opacity 0.15s ease-in
.fade-enter, .fade-leave-to
  opacity: 0  

.fade-append-leave-active
  transition: opacity .2s
.fade-append-enter-active 
  transition: opacity 0.15s ease-in
.fade-append-enter //, .fade-delayed-leave-to
  opacity: 0

</style>
