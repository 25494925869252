<template>
  <div>
    <!-- Слайдер -->
    <section class="bg-light d-flex justify-content-center align-items-center pt-2">
      <b-container>
          <b-row>
              <b-col>
                  <NewsCarousel 
                    v-bind:goalName="'CarouselBlock'"
                    v-bind:dataFeed="'mixed'"
                    class="mb-3"
                  >
                  </NewsCarousel>
              </b-col>
          </b-row>
      </b-container>
    </section>
    <!-- Текст -->
    <section class="py-3">
      <b-container>
        <b-row>
          <b-col>
            <NewsList                            
              v-bind:goalName="'TextBlock'"
              v-bind:dataFeed="'mixed'"
              v-bind:limit="10"
            ></NewsList>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- Оверлей -->
    <section>
      <b-container>
          <b-row>
              <b-col>
                  <OverlayBlock
                      v-bind:goalName="'OverlayBlock'"
                      v-bind:infinity="true"
                      v-bind:limit="0"
                      v-bind:dataFeed="'mixed'"
                  ></OverlayBlock>
              </b-col>
          </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import utils from '../functions/functions'
import { mapActions } from 'vuex'
import NewsList from '@/components/NewsList.vue'
import NewsCarousel from '@/components/NewsCarousel.vue'
import OverlayBlock from '@/components/OverlayBlock.vue'

export default {
  components: {
    NewsList,
    NewsCarousel,
    OverlayBlock
  },   
  data(){
    return {

    }
  },
  computed: {

  },
  methods: {
    ...mapActions(['SMI2_FETCH', 'SCREEN_CHECK', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE']),
  },
  created: function(){
    this.SCREEN_CHECK()
    this.FEED_COUNT_RESET()
    this.FEED_SUM_DEFINE(1)
    this.SMI2_FETCH(this)
  },
}
</script>
