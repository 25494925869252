import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/main.sass'

import utils from './functions/functions'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('GetContent', () => import('@/components/GetContent'))

Vue.config.productionTip = false


import VueYandexMetrika from 'vue-yandex-metrika'   
  Vue.use(VueYandexMetrika, {
    id: utils.counterID(), 
    router: router,
    env: (!utils.isDevelopment) ? 'production' : 'development',
    options: {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true,
    }
  })
 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
