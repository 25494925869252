import Vue from 'vue'
import Vuex from 'vuex'
import integrations from '../modules/integrations'
import screen from '../modules/screen'
import devices from '../modules/devices'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    integrations,
    screen,
    devices,
  }
})
