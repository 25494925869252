<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <!-- <transition name="fade-append"> -->
            <div v-if="contentReady" :class="wrapClass">
                <OverlayBlockItem v-for="(item, index) in blockItems"
                    v-bind:key = index
                    v-bind:item = item
                    v-bind:aspect="aspect"
                    v-bind:siteContent="siteContent"
                    v-on:itemClick="itemClick"   
                    v-bind:wopenTrigger="wopenTrigger"                     
                >
                </OverlayBlockItem>                        
                <!-- маркер прокрутки -->
                <div v-if="infinity" class="w-100 d-block" style="height: 1px;" v-b-visible="itemsIncrease"></div>                        
            </div>
        <!-- </transition> -->

        <div v-if="!contentReady" :class="wrapClass">
            <OverlayBlockPreloader v-for="(item, index) in blockPreloader"
                v-bind:key="index"
                v-bind:aspect="aspect"
                v-bind:siteContent="siteContent"
            ></OverlayBlockPreloader>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import utils from '@/functions/functions'
import OverlayBlockItem from './OverlayBlockItem'
import OverlayBlockPreloader from './OverlayBlockPreloader'

export default {
    components: {
        OverlayBlockItem,
        OverlayBlockPreloader
    },
    data(){
        return{
            blockPreloader: ['', '', '', '', '', ''], 
            blockItems: [],
            blockItemsLocked: [],
            wrapClass: 'd-flex flex-wrap justify-content-center justify-content-sm-between pt-3',
        }
    },
    props: ['goalName', 'limit', 'dataFeed', 'wopenTrigger'],    
    computed: {
        ...mapGetters(['REFRESHING']),
        contentReady: function(){
            return (this.blockItems.length && !this.REFRESHING) ? true : false
        },
        aspect: function(){
            return (this.siteContent) ? '16:9' : '1:1'
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },
        infinity: function(){
            return (!this.limit) ? true : false
        }
    },
    methods: {
        itemsIncrease: function(isVisible){
            if (isVisible && this.blockItems.length){
                const shuffled = utils.shuffleArray(this.blockItemsLocked)
                this.blockItems = this.blockItems.concat(shuffled)
            }
        },        
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        },
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            const limit = (this.limit) ? this.limit : arr.length
            this.blockItems = shuffled.slice(0, limit)
            //лочим в неизменном виде
            this.blockItemsLocked = arr
        },
    },
    watch: {
        REFRESHING: function(){
            if (this.REFRESHING){
                this.blockItems = []
            }
        }
    }
}
</script>
