<template>
    <b-navbar fixed="top" class="py-0 px-3 d-flex justify-content-between align-items-center">
        <div class="text-uppercase d-flex align-items-center" @click="refresh">
            <strong class="text-white">Главные</strong>
            &nbsp;&nbsp;
            <b>Новости</b>
        </div>
        <b-button size="sm" variant="info" class="text-white d-flex align-items-center" @click="newsUpdate" :disabled="this.REFRESHING">
            <span>Обновить</span>
            <b-icon icon="arrow-repeat" class="ml-1"></b-icon>
        </b-button>
    </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '@/functions/functions.js'
export default {
    computed: {
        ...mapGetters(['REFRESHING']),
    },
    methods: {
        ...mapActions(['FAKE_RESET']),
        newsUpdate: function(){
            utils.safeGoal('ButtonClick')
            this.FAKE_RESET()
        },
        refresh: function(){
            utils.safeGoal('reload')
            document.location.reload()
        }
    },
    created: function(){

    }
}
</script>


<style lang="sass" scoped>
nav
    background-color: #232323
    height: 56px
    box-shadow: 0 1px 5px 0 rgba(35,35,35,1)
    z-index: 1
    &::before
        position: absolute
        top: 0
        left: 0
        right: 0
        content: ''
        width: 100%
        height: 4px
        background-color: #206876
div
    font-size: 20px
    font-weight: 800
    margin-right: 10px
    text-decoration: none !important
    cursor: pointer
b
    color: #545454
    &::first-letter
        color: #206876    
button
    background-color: #206876

strong    
    font-weight: 800 !important
span    
    display: none
@media (min-width: 368px)
    span    
        display: block
</style>