<template>
    <div class="app-block position-relative rounded overflow-hidden flex-shrink-0 mb-3 pb-3">
        <b-aspect :aspect="aspect"></b-aspect>
        <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0;">
            <b-skeleton height="100%"></b-skeleton>
        </div>        
    </div>
</template>


<script>
export default {
    components: {

    },    
    props: ['aspect'],
    computed: {

    },
    mounted: function(){

    }
}
</script>